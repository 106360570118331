import React from "react";
import "../styles/impressum.css";

const Impressum = () => {
    return (
        <div className="impressum-page">
            <h1 className="imp-heading">Impressum</h1>
            <h4 className="impressum-lawtext-small-heading">
                NodiaTech UG (haftungsbeschränkt)
            </h4>
            <p className="impressum-lawtext">
                Äußere Schneeberger Straße 35<br />
                08056 Zwickau<br />
                Telefon: +49 (0) 3661 / 4554746<br />
                E-Mail: <a href="mailto:info@nodiatech.de" className="impressum-mail-link">info@nodiatech.de</a>
            </p>
            <br />
            <h4 className="impressum-lawtext-small-heading">Handelsregistereintrag:</h4>
            <p className="impressum-lawtext">Amtsgericht Chemnitz HRB 36973</p>
            <br />
            <h4 className="impressum-lawtext-small-heading">Geschäftsführer:</h4>
            <p className="impressum-lawtext">Jan Ehlig</p> <br />
            <h4 className="impressum-lawtext-small-heading">USt-IdNr:</h4>
            <p className="impressum-lawtext">DE 360 958 277</p> <br />
            <h4 className="impressum-lawtext-small-heading">
                Online-Schlichtung gemäß Art. 14 Abs. 1 ODR-VO und § 36 VSBG:
            </h4>
            <p className="impressum-lawtext">
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter
                <a href="https://ec.europa.eu/consumers/odr/" target="_SEJ" rel="noopener"> https://ec.europa.eu/consumers/odr/ </a>
                finden. Zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle sind wir nicht verpflichtet und grundsätzlich nicht bereit.
            </p>
            <div className="imp-divider"></div>
        </div>
    );
};

export default Impressum;

